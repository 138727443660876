var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"nospace"},[_c('v-card-text',{staticClass:"nospace"},[_c('v-data-table',{staticStyle:{"word-break":"break-all"},attrs:{"id":"RequestListTable","hide-actions":"","headers":_vm.headers,"items":_vm.items,"disable-initial-sort":true,"pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(" "+_vm._s(props.item.customerDetails.customerSerialNumber)+" "),_c('activityLogs',{attrs:{"permissions":'view-logs-request-callback',"type":'requestTicket',"_id":props.item._id,"id":props.item.customerDetails.customerSerialNumber.toString()}})],1),_c('td',[(props.item.workingCountry == 'PAK')?_c('img',{attrs:{"src":require("../../assets/pakflag.png"),"height":"20px"}}):_vm._e(),(props.item.workingCountry == 'KSA')?_c('img',{attrs:{"src":require("../../assets/ksaflag.png"),"height":"20px"}}):_vm._e(),(props.item.workingCountry == 'UAE')?_c('img',{attrs:{"src":require("../../assets/Uaeflag.png"),"height":"20px"}}):_vm._e(),(props.item.workingCountry == 'UAE LA')?_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":require("../../assets/Uaeflag.png"),"height":"20px"}}):_vm._e(),_c('br'),_vm._v(_vm._s(props.item.workingCountry || "-")+" ")]),(
              props.item.customerDetails.name ||
              props.item.customerDetails.email
            )?_c('td',[(!props.item.customerDetails.name)?_c('v-icon',{staticClass:"request-callback-actions",attrs:{"flat":"","prepend-icon":"","color":"blue","title":"Edit Customer"},nativeOn:{"click":function($event){return _vm.editCustomer(props.item.customerDetails.userId)}}},[_vm._v("edit")]):_vm._e(),(!props.item.customerDetails.name)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(props.item.customerDetails.name)+" "),(props.item.customerDetails.name)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(props.item.customerDetails.email)+" "),(props.item.customerDetails.name)?_c('br'):_vm._e(),_vm._v(" "+_vm._s(props.item.customerDetails.phoneNo)+" ")],1):_c('td',[_c('v-icon',{staticClass:"request-callback-actions",attrs:{"flat":"","prepend-icon":"","color":"blue","title":"Add Customer"},nativeOn:{"click":function($event){return _vm.createCustomer()}}},[_vm._v("add")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.getTime(props.item.creationDate) || "N.A")+" "),_c('br'),_vm._v(" "+_vm._s(props.item.purpose || "N.A")+" ")]),_c('td',[_vm._v(_vm._s(props.item.bookingId || "N.A"))]),_c('td',[_vm._v(" "+_vm._s(props.item.requestId || "N.A")+" "),_c('br'),_vm._v(" "+_vm._s(_vm.getStatus(props.item) || "N.A")+" ")]),_c('td',[_vm._v(_vm._s(props.item.Comments || "N.A"))]),(props.item.exceutive.name || props.item.exceutive.email)?_c('td',[_c('div',[_vm._v(_vm._s(props.item.exceutive.name))]),_c('div',[_vm._v(_vm._s(props.item.exceutive.email))])]):_c('td',[_vm._v("NA")]),_c('td',{},[(
                (props.item.bookingId == '-' ||
                  props.item.bookingId == null) &&
                props.item.isAcknowedged &&
                !props.item.isClosed
              )?_c('button',{directives:[{name:"permissions",rawName:"v-permissions",value:('create-booking-request-callback'),expression:"'create-booking-request-callback'"}],staticClass:"track-ass-btn",attrs:{"title":"Create Booking","disabled":_vm.loading},on:{"click":function($event){return _vm.checkAdd(props.item)}}},[_vm._v(" Add ")]):_vm._e(),(!props.item.isClosed && props.item.isAcknowedged)?_c('CloseTicket',{directives:[{name:"permissions",rawName:"v-permissions",value:('cancel-request-callback'),expression:"'cancel-request-callback'"}],attrs:{"ticketId":props.item._id,"callbackCloseTicket":_vm.callbackCloseTicket}}):_vm._e(),(!props.item.isAcknowedged)?_c('button',{directives:[{name:"permissions",rawName:"v-permissions",value:('acknowledge-request-callback'),expression:"'acknowledge-request-callback'"}],staticClass:"assign-driver-btn",attrs:{"disabled":_vm.loading,"title":"Acknowledge Ticket"},on:{"click":function($event){return _vm.checkAck(props.item._id)}}},[_vm._v(" Acknowledge ")]):_vm._e()],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":_vm.isNoSearchResult,"color":"blue","icon":"warning"},slot:"no-results"},[_vm._v("Your search for \""+_vm._s(_vm.search)+"\" found no results.")])],1),_c('pagination',{attrs:{"totalPages":_vm.totalPages,"pages":this.pages,"url":_vm.listApiUrl,"callback":_vm.callback,"componentKey":_vm.componentKey,"action":"request","payload":_vm.payload,"type":1},on:{"pageNo":_vm.page,"pagination-load":_vm.checkLoading}})],1)],1),_c('success-dialog',{attrs:{"content":_vm.x.message,"show":_vm.x.success,"onclose":_vm.closeMessage}}),(_vm.x.error)?_c('v-snackbar',{staticClass:"white--text",attrs:{"timeout":6000,"bottom":"","color":"red darken-2"},model:{value:(_vm.x.error),callback:function ($$v) {_vm.$set(_vm.x, "error", $$v)},expression:"x.error"}},[_vm._v(_vm._s(_vm.x.error))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }